import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-programmeur-analyste',
  templateUrl: './programmeur-analyste.component.html',
  styleUrls: ['./programmeur-analyste.component.scss']
})
export class ProgrammeurAnalysteComponent implements OnInit {

  constructor(private titleService:Title, private meta:Meta) {
    this.titleService.setTitle("AEC Programmeur-Analyste | Multihexa Formation Collégiale");
    this.meta.updateTag({ name: 'description', content: "Pour devenir développeur web, gestionnaire de base de données ou programmer des applications web, notre formation de programmeur-analyste est idéale!" })
  }

  ngOnInit() {
    var element = document.getElementById("mainSection");
    element.scrollIntoView({ behavior: "smooth" });
  }

}

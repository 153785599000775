import { Component, OnInit } from '@angular/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { DataService } from '../data.service';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';
import { Router } from '@angular/router';
import { DatePipe, CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-calendrier',
  templateUrl: './calendrier.component.html',
  styleUrls: ['./calendrier.component.scss']
})
export class CalendrierComponent implements OnInit {
  modalRef: MDBModalRef;
  calendarPlugins = [dayGridPlugin, interactionPlugin];
  formations: any;

  constructor(private dataService: DataService, private modalService: MDBModalService,private router:Router, private datePipe:DatePipe, private currencyPipe:CurrencyPipe) {
    this.dataService.get('formationspublics').subscribe(res => {
      //console.log(res);
      this.formations = res;
    });
  }

  handleEventClick(ev) {
    //console.log(ev.event.id);
    var form = this.formations.find(t => t.id == ev.event.id);
    //console.log(form);
    this.modalRef = this.modalService.show(ConfirmModalComponent, {
      data: {
        heading: ev.event.title,
        content: { heading: 'S\'inscrire à ' + ev.event.title + ' en date du ' + this.datePipe.transform(ev.event.start, 'dd MMMM yyyy') + " de " + this.datePipe.transform(ev.event.start, 'HH:mm') + " à " + this.datePipe.transform(ev.event.end, 'HH:mm') + ". Le prix est " + (form.prix != null ? this.currencyPipe.transform(form.prix,"$") + " par personne/par jour.":"sur demande.") }
      }
    });

    this.modalRef.content.action.subscribe((result: boolean) => {
      if (result) {
        this.router.navigate(["/inscription/formationpublic/"+ ev.event.id])
      }
    });
  }

  ngOnInit() {
  }

}

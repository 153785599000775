import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-gestionnaire-reseaux',
  templateUrl: './gestionnaire-reseaux.component.html',
  styleUrls: ['./gestionnaire-reseaux.component.scss']
})
export class GestionnaireReseauxComponent implements OnInit {

  constructor(private titleService: Title, private meta: Meta) {
    this.titleService.setTitle("AEC Gestionnaire de Réseau | Multihexa Formation Collégiale");
    this.meta.updateTag({ name: 'description', content: "Vous aimeriez être responsable du réseau informatique et de la configuration des serveurs d'une organisation? L'AEC Gestionnaire de Réseau est pour vous." })
  }

  ngOnInit() {
    var element = document.getElementById("mainSection");
    element.scrollIntoView({ behavior: "smooth" });
  }

}

import { Component, OnInit } from '@angular/core';
import { MessageService } from '../message.service';
import { ToastService } from 'ng-uikit-pro-standard';
import { DataService } from '../data.service';
import { Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-inscription',
  templateUrl: './inscription.component.html',
  styleUrls: ['./inscription.component.scss']
})
export class InscriptionComponent implements OnInit {
  model: QuickFormViewModel = new QuickFormViewModel();
  formResultMsg: any;
  countriesOptions: any;
  formAjaxIsComplete: boolean = false;
  currentId: number = null;
  programmes: any;

  constructor(private service: MessageService, private toast: ToastService, private router: Router, private dataService: DataService, private titleService: Title, private meta: Meta) {
    this.programmes = [
      { value: 1, label: "Programmeur-Analystes" },
      { value: 2, label: "Gestionnaire de réseau" }
    ]
    this.titleService.setTitle("Inscription Attestation d’Études Collégiales | Multihexa");
    this.meta.updateTag({ name: 'description', content: "Pour obtenir plus d’informations à propos du processus d’inscription à nos formations collégiales, remplissez notre formulaire et nous communiquerons avec vous." })
  }

  ngOnInit() {
    //var element = document.getElementById("formContact");
    //element.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
  }

  alertInvalid() {
    this.toast.error("Veuillez remplir le formulaire au complet");
  }

  sendForm() {
    this.formAjaxIsComplete = true;
    if (this.model.Programme == 1) {
      this.model.Message = "Demande d'inscription comme programmeur analyste";
      this.service.sendMsgCollegeInscription(this.model).subscribe(res => {
        this.getFormResult(res, true);
        this.router.navigate(["/inscription/success"])
      }, err => {
        this.getFormResult(err, false);
      });
    }
    if (this.model.Programme == 2) {
      this.model.Message = "Demande d'inscription comme gestionnaire de réseau";
      this.service.sendMsgCollegeInscription(this.model).subscribe(res => {
        this.getFormResult(res, true)
        this.router.navigate(["/inscription/success"])
      }, err => {
        this.getFormResult(err, false)
      });
    }
  }

  getFormResult(msg, isValid) {
    if (!isValid) {
      this.toast.error("Erreur lors de l'envoie du message");
      this.formAjaxIsComplete = false;
    }
    else {
      this.toast.success(msg);
      this.model = new QuickFormViewModel();
      this.formAjaxIsComplete = false;
    }
  }

}
export class QuickFormViewModel {
  Name: string;
  Email: string;
  Country: string;
  Message: string;
  Telephone: string;
  City: string;
  MeilleurTemps: string;
  Programme: number;
}

import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-formations',
  templateUrl: './formations.component.html',
  styleUrls: ['./formations.component.scss']
})
export class FormationsComponent implements OnInit {
  categories = new Array<Categorie>();
  currentCat: HTMLElement;
  subcategories = new Array<SubCategory>();
  search = "";
  p: any;

  constructor(private dataService: DataService,private titleService:Title,private meta:Meta) {
    this.titleService.setTitle("Formations aux Entreprises | Multihexa")
    this.meta.updateTag({ name: 'description', content: "Marketing Web, logiciels bureautiques ou graphiques, nous avons de nombreuses formations. Contactez-nous pour plus de détails." })

    this.dataService.get("categories").subscribe((res: Array<Categorie>) => {
      this.categories = res;
    })
    this.getSubCategories(0);
  }

  ngOnInit() {
  }

  getSubCategories(id) {
    if (id != 0) {
      this.dataService.getSingle("subcategories/bycat", id).subscribe((res: Array<SubCategory>) => {
        this.subcategories = res;
      })
    }
    else {
      this.dataService.get("subcategories").subscribe((res: Array<SubCategory>) => {
        this.subcategories = res;
      })
    }
  }

  getByCategory(catId, event) {
    //p c'est le paging
    this.p = 1;
    this.currentCat.classList.remove("active");
    this.currentCat = event.srcElement;
    event.srcElement.classList.add("active");
    this.getSubCategories(catId);
  }

  ngAfterViewInit() {
    this.currentCat = document.querySelector(".list-group-item");
  }
}
export class Categorie {
  catId: number
  catName: string
  isActive: boolean
}
export class SubCategory {
  subCatId:number
  subCatName:string
  catId: number
  isActive:boolean
  subCatShortName: string
  image: string
}

import { Component, OnInit} from '@angular/core';
import { MessageService } from '../message.service';
import { ToastService } from 'ng-uikit-pro-standard';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '../data.service';
import { Title, Meta } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-inscriptionpublic',
  templateUrl: './inscriptionpublic.component.html',
  styleUrls: ['./inscriptionpublic.component.scss']
})
export class InscriptionpublicComponent implements OnInit {
  model: any = {};
  formResultMsg: any;
  countriesOptions: any;
  formAjaxIsComplete: boolean = false;
  currentId: number = null;

  constructor(private datePipe: DatePipe, private service: MessageService, private toast: ToastService, private route: ActivatedRoute, private dataService: DataService, private titleService: Title, private meta: Meta) {
    this.titleService.setTitle("Multihexa | Formation public en Informatique");
    this.meta.updateTag({ name: 'description', content: "Contactez-nous, nous vous répondrons dans un délai de 48 heures ouvrables." })

    this.route.params.subscribe(res => {
      if (res.id != null) {
        this.currentId = res.id;
        this.dataService.getSingle("formationspublics", res.id).subscribe((res: FormationPublic) => {
          console.log(res);
          this.model.Message = "Bonjour, je serais intéressé(e) par le module " + res.moduleName + " en date du " + datePipe.transform(res.start, 'dd MMMM yyyy') + " à " + datePipe.transform(res.start, 'hh:mm') + ". Est-ce possible de me contacter par téléphone ou courriel."
        })
      }
    })
  }

  ngOnInit() {
    var element = document.getElementById("formContact");
    element.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
  }

  alertInvalid() {
    this.toast.error("Veuillez remplir le formulaire au complet");
  }

  sendForm() {
    this.formAjaxIsComplete = true;
    if (this.currentId != null) {
      this.service.sendMsgFormation(this.model).subscribe
        (res => {
          this.getFormResult(res, true)
        }, err => {
          this.getFormResult(err, false)
        });
    }
    else {
      this.service.sendMsgFormation(this.model).subscribe(res => { this.getFormResult(res, true) }, err => { this.getFormResult(err, false) });
    }
  }

  getFormResult(msg, isValid) {
    if (!isValid) {
      this.toast.error("Erreur lors de l'envoie du message");
      this.formAjaxIsComplete = false;
    }
    else {
      this.toast.success(msg);
      this.model = {};
      this.formAjaxIsComplete = false;
    }
  }
}

export class FormationPublic {
  id: number;
  end: Date;
  moduleName: string;
  start:Date
}

import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { MDBModalRef } from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent implements OnInit {
  heading: string;
  content: any;
  action: Subject<boolean> = new Subject();

  constructor(public modalRef: MDBModalRef) { }

  subscribe() {
    this.action.next(true);
  }

  ngOnInit() {
  }

}

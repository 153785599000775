import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  constructor(private http: HttpClient) { }
 
  //url:string = "http://localhost:63244/";

  sendMsgCollegeInscription(msg) {
    try {
      return this.http.post("api/messages/inscription", msg);
    } catch (e) {
      return e;
    }
  }
  sendMsgCollege(msg) {
    try {
      return this.http.post("api/messages/college", msg);
    } catch (e) {
      return e;
    }
  }
  sendMsgFormation(msg) {
    try {
      return this.http.post("api/messages/formation", msg);
    } catch (e) {
      return e;
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-entreprise-online',
  templateUrl: './entreprise-online.component.html',
  styleUrls: ['./entreprise-online.component.scss']
})
export class EntrepriseOnlineComponent implements OnInit {

  constructor(private titleService: Title, private meta:Meta) {
    this.titleService.setTitle("Formations aux Entreprises En Ligne | Multihexa")
    this.meta.updateTag({ name: 'description', content: "100% en ligne, classes virtuelles à distance." })
  }

  ngOnInit() {
  }

}

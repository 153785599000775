import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-passerelleuqac',
  templateUrl: './passerelleuqac.component.html',
  styleUrls: ['./passerelleuqac.component.scss']
})
export class PasserelleuqacComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    var element = document.getElementById("mainSection");
    element.scrollIntoView({ behavior: "smooth" });
  }

}

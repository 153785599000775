import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-college',
  templateUrl: './college.component.html',
  styleUrls: ['./college.component.scss']
})
export class CollegeComponent implements OnInit {

  constructor(private titleService: Title, private meta: Meta ) {
    this.titleService.setTitle("À Propos du Collège | Multihexa");
    this.meta.updateTag({ name: 'description', content: "Multihexa favorise l’accomplissement et développe le plein potentiel de ses élèves. Apprenez-en plus sur notre mission et nos valeurs." })
  }

  ngOnInit() {
  }

}


import { BrowserModule, Title, Meta } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, NO_ERRORS_SCHEMA, LOCALE_ID } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { AppComponent } from './app.component';

import { FullCalendarModule } from '@fullcalendar/angular';
import { MDBSpinningPreloader, MDBBootstrapModulesPro, ToastModule, SmoothscrollModule, ModalModule, CarouselModule } from 'ng-uikit-pro-standard';
import { AccueilComponent } from './accueil/accueil.component';
import { CollegeComponent } from './college/college.component';
import { ContactComponent } from './contact/contact.component';
import { GestionnaireReseauxComponent } from './gestionnaire-reseaux/gestionnaire-reseaux.component';
import { ProgrammeurAnalysteComponent } from './programmeur-analyste/programmeur-analyste.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { GtagModule } from 'angular-gtag';
import { FaqComponent } from './faq/faq.component';
import { HumainsmultihexaComponent } from './humainsmultihexa/humainsmultihexa.component';
import { PasserelleuqacComponent } from './passerelleuqac/passerelleuqac.component';
import { ListecoursComponent } from './listecours/listecours.component';
import { FormationsComponent } from './formations/formations.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { MyFilterPipe } from './filters/search-filter.pipe';
import { CollegialOnlineComponent } from './collegial-online/collegial-online.component';
import { EntrepriseOnlineComponent } from './entreprise-online/entreprise-online.component';
import { InscriptionComponent } from './inscription/inscription.component';
import { CalendrierComponent } from './calendrier/calendrier.component';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { InscriptionpublicComponent } from './inscriptionpublic/inscriptionpublic.component';
import { DatePipe, CurrencyPipe } from '@angular/common';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { ReplacePipe } from './pipes/replace.pipe';

// the second parameter 'fr' is optional
registerLocaleData(localeFr, 'fr');

@NgModule({
  declarations: [
    AppComponent,
    AccueilComponent,
    CollegeComponent,
    ContactComponent,
    GestionnaireReseauxComponent,
    ProgrammeurAnalysteComponent,
    FaqComponent,
    HumainsmultihexaComponent,
    PasserelleuqacComponent,
    ListecoursComponent,
    FormationsComponent,
    MyFilterPipe,
    CollegialOnlineComponent,
    EntrepriseOnlineComponent,
    InscriptionComponent,
    CalendrierComponent,
    ConfirmModalComponent,
    InscriptionpublicComponent,
    ReplacePipe
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    NgxPaginationModule,
    FullCalendarModule,
    HttpClientModule,
    HttpModule,
    ToastModule.forRoot({ preventDuplicates: true }),
    MDBBootstrapModulesPro.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    SmoothscrollModule,
    ModalModule,
    CarouselModule,
    GtagModule.forRoot({ trackingId: 'UA-32119136-1', trackPageviews: true })
  ],
  entryComponents: [ConfirmModalComponent],
  providers: [MDBSpinningPreloader, Title, Meta, DatePipe,CurrencyPipe, { provide: LOCALE_ID, useValue: 'fr' }],
  bootstrap: [AppComponent],
  schemas: [NO_ERRORS_SCHEMA]
})
export class AppModule { }

import { Component, OnInit } from '@angular/core';
import { ToastService } from 'ng-uikit-pro-standard';
import { MessageService } from '../message.service';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
  model: any;
  formAjaxIsComplete = false;
  constructor(private toast: ToastService, private service: MessageService,private titleService:Title,private meta:Meta) {
    this.titleService.setTitle("Foire aux Questions Fréquentes | Multihexa");
    this.meta.updateTag({ name: 'description', content: "Consultez cette page pour obtenir des réponses à plusieurs questions qui nous sont fréquemment posées." })

    this.model = {
      Name: "",
      Email: "",
      Telephone: "",
      Message:""
    }
  }

  ngOnInit() {
  }

  alertInvalid() {
    this.toast.error("Veuillez remplir le formulaire au complet");
  }

  sendForm() {
    this.formAjaxIsComplete = true;
    console.log(this.model);
    this.service.sendMsgCollege(this.model).subscribe(res => { this.getFormResult(res, true) }, err => { this.getFormResult(err, false) });
  }

  getFormResult(msg, isValid) {
    if (!isValid) {
      this.toast.error("Erreur lors de l'envoie du message");
      this.formAjaxIsComplete = false;
    }
    else {
      this.toast.success(msg);
      this.model = {};
      this.formAjaxIsComplete = false;
    }
  }

}

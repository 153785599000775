import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-collegial-online',
  templateUrl: './collegial-online.component.html',
  styleUrls: ['./collegial-online.component.scss']
})
export class CollegialOnlineComponent implements OnInit {

  constructor(private titleService: Title,private meta:Meta) {
    this.titleService.setTitle("Formations Collégiales En Ligne Classes Virtuelles | Multihexa")
    this.meta.updateTag({ name: 'description', content: "Attestations d’études collégiales offertes en classes virtuelles 100% à distance. Formations reconnues partout au Québec. 1200 emplois à combler!" })
  }

  ngOnInit() {
  }

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '../data.service';
import { SubCategory } from '../formations/formations.component';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-listecours',
  templateUrl: './listecours.component.html',
  styleUrls: ['./listecours.component.scss']
})
export class ListecoursComponent implements OnInit {
  formations = new Array<Formation>();
  loadingData = true;

  constructor(private route: ActivatedRoute, private dataService: DataService,private titleService:Title,private meta:Meta) {
    
    this.route.params.subscribe(res => {
      this.dataService.getSingle("formations", res.id).subscribe((res: Array<Formation>) => {
        //console.log(res);
        this.titleService.setTitle(res[0] .subCat.subCatName +" | Multihexa Entreprises");
        this.meta.updateTag({ name: 'description', content: "Notre formation " + res[0].subCat.subCatName  + " vous permettra d’approfondir vos compétences en informatique. Cliquez ici pour plus de détails." })

        this.formations = res;
        this.loadingData = false;
      })
    })
  }

  ngOnInit() {
  }

}

export class Formation {
  formId: number
  formName: string
  subCatId: number
  isActive: boolean
  orderBy: number
  image: string
  modules: Array<Module>
  subCat: SubCategory

  constructor() {
    this.modules = new Array<Module>();
    this.subCat = new SubCategory();
  }
}

export class Module {
  moduleId: number
  moduleName: string
  formId: number
  isActive: boolean
  duration: number
  haveLessonPlan: boolean
  orderNum: number
  lessonPlan: string
  form: Formation
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class FacebookService {
  url = "https://graph.facebook.com/312950369645640/feed?access_token=EAAEp43zZCmrUBAFIym03w3SRkYn9vnP8vu80IinsBgGa9VsrAdr4mzKRIQmdJ2sqOZCA6vQjCrTPV9WhKdpNG2cZBhHZAOHj6xj26SXFJyF54POSMaZCilK22KH3nRJbpYk7HWJ41MUTi9IBYFZBZCaWVTRmycDyREckIJZCkHdYQRhZA9s8mOfy6Hgz7zuZCxTmRf6OxmHytyZAT8VtgNfSATKmvE637kvPK7Vw0pjsZADYxAZDZD";

  constructor(private http: HttpClient) { }

  getPosts() {
    return this.http.get(this.url)
  }
}

import { Component, OnInit } from '@angular/core';
import { MessageService } from '../message.service';
import { ToastService } from 'ng-uikit-pro-standard';
import { Router } from '@angular/router';
import { FacebookService } from '../facebook.service';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-accueil',
  templateUrl: './accueil.component.html',
  styleUrls: ['./accueil.component.scss']
})
export class AccueilComponent implements OnInit {
  model: any = {};
  formResultMsg: any;
  countriesOptions: any;
  formAjaxIsComplete: boolean = false;

  constructor(private service: MessageService, private toast: ToastService, private router: Router, private fb: FacebookService,private titleService:Title,private meta:Meta ) {
    this.titleService.setTitle("Collège Multihexa | Formation Collégiale en Informatique");
    this.meta.updateTag({ name: 'description', content: "Le collège Multihexa offre de la formation en ligne, formations aux entreprises et formations collégiales" })

    const options = {
      toastClass: 'opacity multihexa-green',
      closeButton: true,
      positionClass: 'md-toast-bottom-right',
      extendedTimeOut: 6000,
      enableHtml: true
    };
    setTimeout(() => {
      const alertInstance = this.toast.info("<strong>Nouveauté!<br\>Formation en ligne.</strong> Communique avec nous pour en savoir plus.", '', options)

      alertInstance.onTap.subscribe(() => {
        window.scrollTo(0, 0)
        this.router.navigate(['contact']);
      });
    }, 2000);


  }

  ngOnInit() {
  }

  alertInvalid() {
    this.toast.error("Veuillez remplir le formulaire au complet");
  }

  sendForm() {
    this.formAjaxIsComplete = true;
    this.service.sendMsgCollege(this.model).subscribe(
      res => {
        this.toast.success(res);
        this.model = {};
        this.formAjaxIsComplete = false;
      }, err => {
        this.toast.error("Erreur lors de l'envoie du message");
        this.formAjaxIsComplete = false;
    });
  }
}

import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccueilComponent } from './accueil/accueil.component';
import { ContactComponent } from './contact/contact.component';
import { ProgrammeurAnalysteComponent } from './programmeur-analyste/programmeur-analyste.component';
import { GestionnaireReseauxComponent } from './gestionnaire-reseaux/gestionnaire-reseaux.component';
import { CollegeComponent } from './college/college.component';
import { FaqComponent } from './faq/faq.component';
import { HumainsmultihexaComponent } from './humainsmultihexa/humainsmultihexa.component';
import { PasserelleuqacComponent } from './passerelleuqac/passerelleuqac.component';
import { ListecoursComponent } from './listecours/listecours.component';
import { FormationsComponent } from './formations/formations.component';
import { CollegialOnlineComponent } from './collegial-online/collegial-online.component';
import { EntrepriseOnlineComponent } from './entreprise-online/entreprise-online.component';
import { InscriptionComponent } from './inscription/inscription.component';
import { CalendrierComponent } from './calendrier/calendrier.component';
import { InscriptionpublicComponent } from './inscriptionpublic/inscriptionpublic.component';


const routes: Routes = [
  {
    path: "",
    component: AccueilComponent
  },
  {
    path: "contact",
    component: ContactComponent
  },
  {
    path: "contact/success",
    component: ContactComponent
  },
  {
    path: "contact/:id",
    component: ContactComponent
  },
  {
    path: "programmeur-analyste",
    component: ProgrammeurAnalysteComponent
  },
  {
    path: "apropos",
    component: CollegeComponent
  },
  {
    path: "gestionnaire-reseaux",
    component: GestionnaireReseauxComponent
  },
  {
    path: "faq",
    component: FaqComponent
  },
  {
    path: "humainsmultihexa",
    component: HumainsmultihexaComponent
  },
  {
    path: "passerelleuqac",
    component: PasserelleuqacComponent
  },
  {
    path: "listecours/:cat/:id",
    component: ListecoursComponent
  },
  {
    path: "formations/entreprises",
    component: FormationsComponent
  },
  {
    path: "formationsenligne/collegiales",
    component: CollegialOnlineComponent
  },
  {
    path: "formationsenligne/entreprises",
    component: EntrepriseOnlineComponent
  },
  {
    path: "formations/enligne",
    component: EntrepriseOnlineComponent
  },
  {
    path: "inscription",
    component: InscriptionComponent
  },
  {
    path: "inscription/success",
    component: InscriptionComponent
  },
  {
    path: "formations/public",
    component: CalendrierComponent
  },
  {
    path: "inscription/formationpublic/:id",
    component: InscriptionpublicComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Gtag } from 'angular-gtag';
import { DataService } from './data.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  currentUrl: string;
  year: number = new Date().getFullYear();

  constructor(private router: Router, gtag: Gtag,private dataService:DataService) {
    //Récuperer l'Url dans la variable currentUrl pour fixer la bar de navigation active
    router.events.subscribe((_: NavigationEnd) => this.currentUrl = router.url);
    //this.dataService.getSingle("cours/perdomain", "BUR").subscribe(res => {
    //  console.log(res);
    //})
  }
  
  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });
  }
}
